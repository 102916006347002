<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="faq_category" :translate-params="{id: category.id, name: category.name.it}">Edit Category  #%{id} - %{name}</translate>
    </h1>
    <CategoryForm
        :data="category"
        @save="onSave"
        :save_text="$pgettext('form','Save')"
        :autocomplete_tag="(query) => autocompleteTags({query: query, category: 'faq'})"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";
import CategoryForm from "@/domain/faq/components/CategoryForm.vue";

export default {
  name: 'EditFaqCategory',
  components: {CategoryForm},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchCategory(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'faqCategory.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('faq', [
      'category',
    ]),
  },
  methods: {
    ...mapActions('faq', [
      'fetchCategory',
      'updateCategory',
    ]),
    ...mapActions('core', [
      'autocompleteTags',
    ]),
    async onSave(data) {
      await this.updateCategory({id: this.category.id,data: data})
      await this.$router.push({name: 'faqCategory.list'})
      throw new SuccessNotification(this.$pgettext('faq_category', 'Faq Category updated!'))
    }
  }
}
</script>

<style scoped>

</style>