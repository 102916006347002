<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFormRow>
          <template v-slot:firstColContent>
            {{ $pgettext('faq_category.label', 'Category Name') }}
          </template>
          <template v-slot:secondColContent>
            <PTranslatableTextInput v-model="form.name" :placeholder="$pgettext('faq_category.placeholder','Name')"/>
          </template>
        </PFormRow>
        <PFormRow>
          <template v-slot:firstColContent>
            {{ $pgettext('faq_category.label', 'Tags') }}
          </template>
          <template v-slot:secondColContent>
            <PTagInput v-model="form.tags" :autocomplete="autocomplete_tag" :placeholder="$pgettext('faq_category.placeholder','Tags')"/>
          </template>
        </PFormRow>
        <PFormRow>
          <template v-slot:firstColContent>
            {{ $pgettext('faq_category.label', 'Active') }}
          </template>
          <template v-slot:secondColContent>
            <CSwitch class="mx-1" color="primary"
                     variant="3d" size="sm"
                     :checked.sync="form.is_active"/>
          </template>
        </PFormRow>
        <PFormRow>
          <template v-slot:firstColContent>
            {{ $pgettext('faq_category.label', 'Order') }}
          </template>
          <template v-slot:secondColContent>
            <CInput
                v-model="form.order"
                ref="form-order"
                :placeholder="0"
            />
          </template>
        </PFormRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import PFormRow from "@/components/PFormRow.vue";
import PTagInput from "@/domain/core/components/PTagInput.vue";
import PTranslatableTextInput from "@/domain/core/components/PTranslatableTextInput.vue";

export default {
  name: "CategoryForm",
  components: {PTranslatableTextInput, PTagInput, PFormRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    },
    autocomplete_tag: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      form: {
        name: {},
        order: 0,
        tags: [],
        is_active: true,
      },
    }
  },
  mounted() {
    this.onReset()
  },
  emits: ['save'],
  methods: {
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      this.$emit('save', this.form)
    }
  }
}
</script>

<style scoped>

</style>